import { Search as SearchIcon } from '@mui/icons-material';
import { darken, InputBase, styled } from '@mui/material';
import Router from 'next/router';
import { FormEvent, useCallback, useRef } from 'react';

import { handleError } from '../../../utils/errors';
import { useStringParam } from '../../../utils/hooks';

export default function SearchBox({ large }: { large?: boolean }) {
  const inputRef = useRef<HTMLInputElement>();

  const handleSearch = useCallback((e: FormEvent) => {
    e.preventDefault();
    Router.push({ pathname: '/search', query: { q: inputRef.current?.value } }).catch(handleError);
  }, []);

  return (
    <SearchWrapper sx={{ borderRadius: large ? '9px' : undefined }} onSubmit={handleSearch} action="/search">
      <SearchIconWrapper>
        <SearchIcon fontSize={large ? 'large' : undefined} />
      </SearchIconWrapper>
      <SearchInput
        placeholder="Busque por sócias, serviços ou cidades"
        type="search"
        name="q"
        defaultValue={useStringParam('q')}
        inputRef={inputRef}
        inputProps={{ 'aria-label': 'search' }}
      />
    </SearchWrapper>
  );
}

const searchBgColor = '#E5E5E5';
const searchFgColor = '#545252';

const SearchWrapper = styled('form')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: searchBgColor,
  color: darken(searchFgColor, 0.1),
  '&:hover': {
    backgroundColor: darken(searchBgColor, 0.05),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  display: 'flex',
  [theme.breakpoints.up('lg')]: {
    maxWidth: '44ch',
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  flex: 1,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    width: '100%',
    '&::placeholder': {
      color: searchFgColor,
      opacity: 1,
    },
  },
}));
