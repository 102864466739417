import { Box, BoxProps } from '@mui/material';

import styles from './footer.module.scss';

export default function Footer({ sx }: { sx?: BoxProps['sx'] }) {
  return (
    <Box component="footer" className={styles.footer} sx={sx}>
      <a href="https://dsbrigade.com/" target="_blank" rel="noopener noreferrer">
        Desenvolvido por DSB Labs.
      </a>
    </Box>
  );
}
