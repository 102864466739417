import { AppBar, Box, Container, Toolbar } from '@mui/material';

import { TopLogoButton } from '../../logo';

import DesktopMenu from './desktop-menu';
import MobileMenu from './mobile-menu';
import SearchBox from './search-box';

const mobileOnly = { display: { xs: 'flex', md: 'none' } };
const desktopOnly = { display: { xs: 'none', md: 'flex' } };
const largeOnly = { display: { xs: 'none', lg: 'flex' } };

export default function MainAppBar() {
  return (
    <AppBar position="sticky" elevation={6} sx={{ p: 1, backgroundColor: '#000000' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <TopLogoButton color="white" />

          <Box sx={largeOnly} flexGrow={1}>
            <SearchBox />
          </Box>

          <Box sx={{ display: 'flex', ml: 'auto', alignItems: 'center', gap: 3 }}>
            {/* small-screen menu */}
            <Box sx={{ ...mobileOnly }}>
              <MobileMenu />
            </Box>

            {/* desktop menu */}
            <Box sx={{ ...desktopOnly, gap: 2 }}>
              <DesktopMenu />
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
