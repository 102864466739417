import { Backdrop, Box, Button } from '@mui/material';
import Link from 'next/link';

import styles from './paywall.module.scss';

export default function Paywall() {
  return (
    <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.appBar - 1 }}>
      <Box className={styles.paywall}>
        <h1>O sonho se tornou realidade!</h1>
        <p>
          Agora temos um portal para chamar de nosso! No entanto, para que você tenha acesso à comunidade Sou Sócia é
          necessário que antes você se torne uma. Faça parte e descubra um novo mundo de possibilidades. Portal W é a
          rede social da Sócia W2W.
        </p>
        <Box className={styles.buttons}>
          <Button color="inverted" size="large" href="https://sou-socia.clubw2w.com.br/pre-inscricoes" target="_blank">
            Assine agora
          </Button>
          <Link href="/" passHref legacyBehavior>
            <Button color="inverted" variant="text">
              Voltar à página inicial
            </Button>
          </Link>
        </Box>
      </Box>
    </Backdrop>
  );
}
