import { Box, BoxProps } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import styles from './logo.module.scss';

interface Props {
  color?: string;
  sx?: BoxProps['sx'];
}

export default function Logo({ color, sx }: Props) {
  return (
    <Box
      className={styles.logo}
      sx={{ height: '4em', ...sx, backgroundColor: color ?? ((theme) => theme.palette.primary.main) }}
    >
      <Box sx={visuallyHidden}>PortalW</Box>
    </Box>
  );
}
