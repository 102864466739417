import {
  AccountCircle,
  AddBusiness,
  Business,
  Forum,
  Groups,
  HelpCenter,
  HomeOutlined,
  Key,
  Menu as MenuIcon,
  PowerSettingsNew,
} from '@mui/icons-material';
import { Badge, Divider, IconButton, Menu, Tooltip } from '@mui/material';
import { MouseEvent, useCallback, useId, useState } from 'react';

import { useLogoutCallback, useUserInfo } from '../../../utils/auth';
import { useMessageCount } from '../../../utils/messages';
import { SchoolIcon } from '../../icons';
import { PopupMenuItem } from '../common';

export default function MobileMenu() {
  const handleLogout = useLogoutCallback();
  const unreadMessageCount = useMessageCount();
  const myInfo = useUserInfo();
  const isAuthenticated = myInfo?.isAuthenticated;

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      setAnchorElUser(e.currentTarget);
    },
    [setAnchorElUser],
  );
  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
  }, [setAnchorElUser]);

  const id = useId();

  return (
    <>
      <Tooltip title="Menu">
        <IconButton size="large" color="inherit" onClick={handleOpenUserMenu} sx={{ p: 0.5 }}>
          <Badge badgeContent={unreadMessageCount} color="secondary">
            <MenuIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id={id}
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <PopupMenuItem href="/" icon={<HomeOutlined />}>
          Home
        </PopupMenuItem>
        <PopupMenuItem href="/feed" icon={<Groups />}>
          Feed
        </PopupMenuItem>
        <PopupMenuItem href="/me" icon={<AccountCircle />}>
          Meu Perfil
        </PopupMenuItem>

        {myInfo?.companies?.map(({ slug, id, name }) => (
          <PopupMenuItem key={id} href={`/companies/${slug ?? id}`} icon={<Business />}>
            {name}
          </PopupMenuItem>
        ))}
        <PopupMenuItem href="/me/edit/new" icon={<AddBusiness />}>
          Adicionar Negócio
        </PopupMenuItem>

        <PopupMenuItem href="/messages" icon={<Forum />}>
          <Badge badgeContent={unreadMessageCount} color="error" variant="dot">
            Mensagens
          </Badge>
        </PopupMenuItem>
        <PopupMenuItem href="/faq" icon={<HelpCenter />}>
          FAQ
        </PopupMenuItem>
        <PopupMenuItem href="/classes" icon={<SchoolIcon />}>
          Aulas
        </PopupMenuItem>
        <Divider />

        {isAuthenticated ? (
          <PopupMenuItem onClick={handleLogout} icon={<PowerSettingsNew />}>
            Sair
          </PopupMenuItem>
        ) : (
          <PopupMenuItem href="/auth/login" icon={<Key />}>
            Login
          </PopupMenuItem>
        )}
      </Menu>
    </>
  );
}
