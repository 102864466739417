import Image, { StaticImageData } from 'next/image';

import messageIcon from './message.png';
import signOutIcon from './sign-out.png';
import userIcon from './user.png';

import styles from './icons.module.scss';

function CustomIcon({ src, size = 30, alt = '' }: { src: StaticImageData; size?: number; alt?: string }) {
  return <Image src={src} width={size} height={size} alt={alt} className={styles.whiteFilter} />;
}

export const UserIcon = () => <CustomIcon src={userIcon} />;
export const MessageIcon = () => <CustomIcon src={messageIcon} />;
export const SignOutIcon = () => <CustomIcon src={signOutIcon} />;

export { HelpCenterOutlined as FaqIcon, School as SchoolIcon } from '@mui/icons-material';
