import { Groups, HomeOutlined } from '@mui/icons-material';

import { useLogoutCallback, useUserInfo } from '../../../utils/auth';
import { useMessageCount } from '../../../utils/messages';
import { FaqIcon, MessageIcon, SchoolIcon, SignOutIcon } from '../../icons';
import { DesktopMenuButton, LoginButton } from '../common';

import MyProfileButton from './my-profile-button';

export default function DesktopMenu() {
  const myInfo = useUserInfo();
  const handleLogout = useLogoutCallback();
  const isAuthenticated = myInfo?.isAuthenticated;
  const unreadMessageCount = useMessageCount();

  return (
    <>
      <DesktopMenuButton label="home" href="/" icon={<HomeOutlined />} />
      <DesktopMenuButton label="feed" href="/feed" icon={<Groups />} />
      <MyProfileButton />
      <DesktopMenuButton label="mensagens" href="/messages" icon={<MessageIcon />} badge={unreadMessageCount} />
      <DesktopMenuButton label="FAQ" href="/faq" icon={<FaqIcon />} />
      <DesktopMenuButton label="Aulas" href="/classes" icon={<SchoolIcon />} />

      {isAuthenticated ? (
        <DesktopMenuButton label="Sair" onClick={handleLogout} icon={<SignOutIcon />} />
      ) : (
        <LoginButton />
      )}
    </>
  );
}
