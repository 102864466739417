import { Container, Box } from '@mui/material';
import { PropsWithChildren } from 'react';

import styles from './catchphrase.module.scss';

interface Props {}

export default function Catchphrase({ children }: PropsWithChildren<Props>) {
  return (
    <Box className={styles.catchphrase}>
      <Container maxWidth="lg" component="hgroup">
        <h1>Portal W</h1>
        <h2>{children}</h2>
      </Container>
    </Box>
  );
}
