import Script from 'next/script';
import { useCallback } from 'react';

export default function WhatsappButton() {
  const handleLoad = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
    (window as any).CreateWhatsappChatWidget({
      enabled: true,
      chatButtonSetting: {
        backgroundColor: '#4dc247',
        ctaText: '',
        borderRadius: '25',
        marginLeft: '0',
        marginBottom: '50',
        marginRight: '50',
        position: 'right',
      },
      brandSetting: {
        brandName: 'Portal W',
        brandSubTitle: '',
        brandImg:
          'https://media-exp1.licdn.com/dms/image/C4E0BAQEE7P2TghV4HA/company-logo_200_200/0/1565019406715?e=1676505600&v=beta&t=Zgpg2YNFNn1RoYmdUTy68ItRz9Xs4ZonU7BhjfUSnYE',
        welcomeText: 'Olá! Como posso ajudar?',
        messageText: '',
        backgroundColor: '#0a5f54',
        ctaText: 'Iniciar chat',
        borderRadius: '25',
        autoShow: false,
        phoneNumber: '5548998420089',
      },
    });
  }, []);

  return (
    <Script
      async
      type="text/javascript"
      src="https://wati-integration-service.clare.ai/ShopifyWidget/shopifyWidget.js?21900"
      onLoad={handleLoad}
    />
  );
}
