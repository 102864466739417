import { Badge, ButtonBase, ButtonBaseProps, Typography } from '@mui/material';
import Link from 'next/link';
import { ReactNode } from 'react';

import styles from './desktop-menu-button.module.scss';

interface Props extends Pick<ButtonBaseProps, 'onClick'> {
  icon: ReactNode;
  label: ReactNode;
  badge?: ReactNode;
  href?: string;
}

export default function DesktopMenuButton({ icon, label, href, badge, ...props }: Props) {
  const bb = (
    <ButtonBase className={styles.desktopButton} focusRipple {...props}>
      {!badge ? (
        icon
      ) : (
        <Badge badgeContent={badge} color="secondary">
          {icon}
        </Badge>
      )}
      <Typography>{label}</Typography>
    </ButtonBase>
  );

  return !href ? (
    bb
  ) : (
    <Link href={href} passHref legacyBehavior>
      {bb}
    </Link>
  );
}
