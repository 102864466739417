import { AccountCircle, AddBusiness, Business } from '@mui/icons-material';
import { Divider, Menu } from '@mui/material';
import { MouseEvent, useCallback, useId, useState } from 'react';

import { useUserInfo } from '../../../utils/auth';
import { UserIcon } from '../../icons';
import { DesktopMenuButton, PopupMenuItem } from '../common';

export default function MyProfileButton() {
  const myInfo = useUserInfo();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      setAnchorElUser(e.currentTarget);
    },
    [setAnchorElUser],
  );
  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
  }, [setAnchorElUser]);

  const id = useId();

  return (
    <>
      <DesktopMenuButton label="meu perfil" href="/me" icon={<UserIcon />} onClick={handleOpenUserMenu} />
      <Menu
        id={id}
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <PopupMenuItem href="/me" icon={<AccountCircle />}>
          Perfil Pessoal
        </PopupMenuItem>
        <Divider />
        {myInfo?.companies?.map(({ slug, id, name }) => (
          <PopupMenuItem key={id} href={`/companies/${slug ?? id}`} icon={<Business />}>
            {name}
          </PopupMenuItem>
        ))}
        <PopupMenuItem href="/me/edit/new" icon={<AddBusiness />}>
          Adicionar Negócio
        </PopupMenuItem>
      </Menu>
    </>
  );
}
